import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import Beispiel from '../assets/images/wurzelbehandlung.jpg'
import Layout from '../components/layout'

const TestWrapper = styled.div`
  img {
    top: 0;
    height: 35%;
    width: 45%;
  }
`
 /* width: auto;
    height: 200px;
    margin: 3em !important; */

export default ({ data }) => {
  return (
    <Layout>
      <Helmet title="wurzelbehandlung" />
      <div id="main">
        <hr></hr>
        <section className="main">
        <div
            dangerouslySetInnerHTML={{
              __html: data.allMarkdownRemark.edges[0].node.html,
            }}
          ></div>
          <TestWrapper>
            <img src={Beispiel} alt="" />
          </TestWrapper>
        </section>
      </div>
    </Layout>
  )
}


export const query = graphql`
  query wurzelbehandlung {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "wurzelbehandlung" } } }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`

